
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: false,
      EditSave: false,
      Loading: [] as Array<'Loading'>,
      CookiesData: {
        Items: []
      } as any,
      ErrorsVisibilty: false,
      GrappedItem: null as any,
      GrappedElement: null as any,
      GrappedIndex: null as any,
      NewIndex: null as any,
      CategoriesMap: {} as any,
      DialogMapper: [
        { Path: 'Name', Res: 'D_Catalog_Name' },
        { Path: 'Description', Res: 'D_Catalog_Description', Default: '' },
        { Path: 'ImgURL', Res: 'D_Catalog_Image', IsImage: true, Default: '' },
        // { Path: 'Media', Res: 'D_Catalog_Media', Default: [] },
        { Path: 'Tags', Res: 'D_Catalog_Tags', Default: {} }
        // { Path: 'Items', Res: 'D_Catalog_Items', IDPath: 'ID', Array: [['ID'], ['Name'], ['Code'], ['Description'], ['ImgURL']], Default: [] }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData (Silent = true) {
      this.ReadCatalog(Silent)
    },
    ReadCatalog (Silent = true) {
      if (!Silent) this.Loading.push('Loading')
      this.Get('Catalogs', '/' + this.DialogData.Data.ID).then(response => {
        this.Console(response)
        response.Categories.forEach((category :any) => {
          this.CategoriesMap['Cat_' + category.Sequence] = category
        })
        var modifiedResItems = response.Items.map((item :any) => {
          item.Name = parseInt(item.Name)
          var url = item.ImgURL
          if (url?.indexOf('res.cloudinary') > -1) url = 'https://cookies-bis-storage.fra1.digitaloceanspaces.com/CoreUploads/OldGroup/' + url.split('/')[url.split('/')?.length - 1]
          item.ImgURL = url
          item.Media = item.Media.map((media: any) => {
            var url = media.URL
            if (url?.indexOf('res.cloudinary') > -1) url = 'https://cookies-bis-storage.fra1.digitaloceanspaces.com/CoreUploads/OldGroup/' + url.split('/')[url.split('/')?.length - 1]
            media.URL = url
            return media
          })
          item.QRURL = window.location.hostname + '/I/Q/' + this.DialogData.Data.ID + '/' + item.ID
          return item
        })
        var modifiedResCategories = response.Categories.map((item :any) => {
          var url = item.ImgURL
          if (url?.indexOf('res.cloudinary') > -1) url = 'https://cookies-bis-storage.fra1.digitaloceanspaces.com/CoreUploads/OldGroup/' + url.split('/')[url.split('/')?.length - 1]
          item.ImgURL = url
          return item
        })
        var modifiedResMedia = response.Media.map((item :any) => {
          var url = item.URL
          if (url?.indexOf('res.cloudinary') > -1) url = 'https://cookies-bis-storage.fra1.digitaloceanspaces.com/CoreUploads/OldGroup/' + url.split('/')[url.split('/')?.length - 1]
          item.URL = url
          return item
        })
        var ModifiedRes = response
        var url = ModifiedRes.ImgURL
        if (url?.indexOf('res.cloudinary') > -1) url = 'https://cookies-bis-storage.fra1.digitaloceanspaces.com/CoreUploads/OldGroup/' + url.split('/')[url.split('/')?.length - 1]
        ModifiedRes.ImgURL = url
        ModifiedRes.Items = modifiedResItems
        ModifiedRes.Categories = modifiedResCategories
        ModifiedRes.Media = modifiedResMedia
        this.CookiesData = ModifiedRes
        this.MEMWrite('D_Catalog_Items', ModifiedRes.Items)
        this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.CookiesData)
        if (!Silent) this.Loading.shift() // Remove Loading flag
      }, error => {
        if (!Silent) this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Catalog'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    async SaveData () {
      this.GlobalSave()
      var _this = this
      if (typeof this.MEMRead('D_Catalog_Image') !== 'string' && this.MEMRead('D_Catalog_Image') !== undefined && this.MEMRead('D_Catalog_Image') !== null) {
        await this.PostImage(this.MEMRead('D_Catalog_Image')).then((res :any) => {
          this.MEMWrite('D_Catalog_Image', res)
          PostNow()
        })
      } else {
        PostNow()
      }
      function PostNow () {
        _this.Post('PATCH', 'Catalogs', {
          Name: _this.MEMRead('D_Catalog_Name'),
          Description: _this.MEMRead('D_Catalog_Description'),
          ImgURL: _this.MEMRead('D_Catalog_Image'),
          Configs: _this.CookiesData.Configs,
          IsHidden: _this.CookiesData.IsHidden
        },
        '/' + _this.CookiesData.ID
        ).then((value) => {
          _this.GlobalSaveDone()
        }, error => {
          _this.GlobalSaveDone(false)
          error.CookiesError = 'Error in Editing'
          _this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
      // this.SaveDialogData({
      //   DialogMapper: this.DialogMapper,
      //   Parentthis: this,
      //   PostAPI: 'Catalogs',
      //   OriginalData: this.OriginalData,
      //   PatchAPI: 'Catalogs',
      //   Editing: true,
      //   PatchQuery: this.DialogData.Editing ? '/' + this.DialogData.Data.ID : undefined,
      //   MultiSave: true
      // })
    },
    DataSaved (response :any) {
      this.GlobalSaveDone()
      // this.$router.go(-1)
    },
    PrintTable () {
      const TableDom = document.getElementById('D_Profiles_Print')
      this.PrintIT(TableDom, { PageSize: 'A4 portrait', Header: true })
    },
    AddItem () {
      this.GlobalSave()
      this.Post('POST', 'Catalogs', { Code: 'Code', Name: this.Translate('New Item'), ImgURL: '' }, `/${this.CookiesData.ID}/Items`).then((res: any) => {
        this.ReadDialogData()
        this.GlobalSaveDone()
      }, error => {
        this.GlobalSaveDone(false)
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
      // this.OpenDialog('CatalogItem_Dialog', this.Translate('New Item'), { Data: { Res: 'D_Catalog_Items', Name: this.MEMRead('D_Catalog_Items').length + 1, IsNew: true, Categories: this.AvailableCategories() } })
    },
    AddCategory () {
      this.Saving = true
      this.GlobalSave()
      this.Post('POST', 'Catalogs', { Name: this.Translate('New Category'), Description: this.Translate('Description') }, `/${this.CookiesData.ID}/Categories`).then(() => {
        this.ReadDialogData()
        this.GlobalSaveDone()
        this.Saving = false
      }, error => {
        this.GlobalSaveDone(false)
        this.Saving = false
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
      // this.OpenDialog('CatalogItem_Dialog', this.Translate('New Item'), { Data: { Res: 'D_Catalog_Items', Name: this.MEMRead('D_Catalog_Items').length + 1, IsNew: true, Categories: this.AvailableCategories() } })
    },
    EditItem (e :any, item :any, Attr :string) {
      // this.Saving = true
      this.GlobalSave()
      this.Post('PATCH', 'Catalogs', { [Attr]: e?.target?.innerText }, `/${this.CookiesData.ID}/Items/${item.ID}`).then(() => {
        this.ReadDialogData()
        this.GlobalSaveDone()
        // this.Saving = false
      }, error => {
        this.GlobalSaveDone(false)
        // this.Saving = false
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditItemCategory (item :any, Res: string) {
      this.GlobalSave()
      // this.Saving = true
      this.Post('PATCH', 'Catalogs', { CategorySeq: this.MEMRead(`${Res}_Value`)?.Sequence }, `/${this.CookiesData.ID}/Items/${item.ID}`).then(() => {
        this.ReadDialogData()
        this.GlobalSaveDone()
        // this.Saving = false
      }, error => {
        this.GlobalSaveDone(false)
        // this.Saving = false
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditCategory (e :any, category :any, Attr :string) {
      this.GlobalSave()
      // this.Saving = true
      // console.log(e)
      // console.log(e?.target)
      this.Post('PATCH', 'Catalogs', { [Attr]: e?.target?.innerText }, `/${this.CookiesData.ID}/Categories/${category.ID}`).then(() => {
        this.ReadDialogData()
        this.GlobalSaveDone()
        // this.Saving = false
      }, error => {
        this.GlobalSaveDone(false)
        // this.Saving = false
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    RemoveItem (Item :any, Attr :string) {
      this.Saving = true
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Catalogs', AddQuery: `/${this.CookiesData.ID}/${Attr}/${Item.ID}`, Text: 'Are you sure you want to delete <strong>' + (Item.Code ? Item.Code : '' + ' ' + Item.Name ? Item.Name : '') + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.AfterRemove, AfterDialogFunctionValue: Item.Media?.map((value :any) => { return value.URL }) } as GeneralAcceptDialogData, {})
      // this.Post('DELETE', 'Catalogs', {}, `/${this.CookiesData.ID}/${Attr}/${Item.ID}`).then(() => {
      //   this.ReadDialogData()
      //   this.GlobalSaveDone()
      //   this.Saving = false
      // }, error => {
      //   this.GlobalSaveDone(false)
      //   this.Saving = false
      //   error.CookiesError = 'Error in Editing'
      //   this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      // })
    },
    AfterRemove (urls :string[]) {
      this.ReadDialogData()
      this.Saving = false
      urls.forEach((value) => {
        this.DeleteImage(value)
      })
    },
    CustomSorting (NewSort :any) {
      this.MEMWrite('D_Catalog_Items', NewSort.SortedData)
    },
    AvailableCategories () {
      var Categories :Array<string> = []
      var CategoriesObjArray :any = []
      this.MEMRead('D_Catalog_Items').forEach((element :any) => {
        if (element.Description !== '' && Categories.indexOf(element.Description) === -1) {
          Categories.push(element.Description)
          CategoriesObjArray.push({
            Name: element.Description
          })
        }
      })
      return CategoriesObjArray
    },
    EditCategories () {
      this.OpenDialog('CategoriesTable', this.Translate('Catalog Categories Images'), { Data: this.AvailableCategories() })
    },
    Grapped (event :any, item: any, index :number, type :'Touch' | 'Mouse') {
      this.GrappedElement = event?.target
      this.GrappedItem = item
      this.GrappedIndex = index
      this.NewIndex = index
    },
    StopGrapping () {
      if (this.GrappedItem === null || this.GrappedIndex === null || this.NewIndex === null) {
        this.GrappedItem = null
        this.GrappedIndex = null
        this.NewIndex = null
        return
      }
      this.GlobalSave()
      this.Post('PATCH', 'Catalogs', this.NewIndex, `/${this.CookiesData.ID}/ItemsOrder/${this.GrappedItem?.ID}`).then(() => {
        this.GrappedItem = null
        this.GrappedIndex = null
        this.NewIndex = null
        this.GlobalSaveDone()
        this.ReadDialogData()
        this.Saving = false
      }, error => {
        this.GrappedItem = null
        this.GrappedIndex = null
        this.NewIndex = null
        this.GlobalSaveDone(false)
        this.Saving = false
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
      // if (this.Grapping !== implements) {
      //   this.Console('this.SortIndexEdited')
      //   this.Console(this.SortIndexEdited)
      //   var SortedSorter = this.SortIndexEdited.map((element :any) => {
      //     var Newlement = element
      //     if (this.TableSettings.Sorter) {
      //       this.DeepChanger(Newlement, this.TableSettings.Sorter, element.SorterIndex)
      //     }
      //     return Newlement
      //   })
      //   this.Console(SortedSorter)
      //   this.$emit('CustomSorting', { SortedData: SortedSorter, Sorter: this.TableSettings.Sorter })
      //   this.Grapping.style.opacity = 1
      //   this.Grapping = null
      //   this.GrappedItem = {}
      // }
    },
    EditImage (Item :any, Attr:string, Res:string) {
      this.GlobalSave()
      this.PostImage(this.MEMRead(Res)).then((value :any) => {
        this.Post('PATCH', 'Catalogs', { ImgURL: value }, `/${this.CookiesData.ID}/${Attr}/${Item.ID}`).then(() => {
          this.ReadDialogData()
          this.GlobalSaveDone()
          if (Attr === 'Items') this.MEMWrite(Res, '')
        }, error => {
          this.ReadDialogData()
          this.GlobalSaveDone(false)
          if (Attr === 'Items') this.MEMWrite(Res, '')
          error.CookiesError = 'Error in Editing'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      })
    },
    EditAllCatImage () {
      this.GlobalSave()
      this.PostImage(this.MEMRead('D_Catalog_CategoryImage_All')).then((value :any) => {
        this.Post('PATCH', 'Catalogs', {
          Name: this.MEMRead('D_Catalog_Name'),
          Description: this.MEMRead('D_Catalog_Description'),
          ImgURL: this.MEMRead('D_Catalog_Image'),
          Configs: {
            ...this.CookiesData.Configs,
            All_Category_ImgURL: value
          },
          IsHidden: this.CookiesData.IsHidden
        }, '/' + this.CookiesData.ID).then(() => {
          this.ReadDialogData()
          this.GlobalSaveDone()
        }, error => {
          this.ReadDialogData()
          this.GlobalSaveDone(false)
          error.CookiesError = 'Error in Editing'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      })
    },
    IsMoving (event :any) {
      // console.log(event.clientY)
      if (this.GrappedItem !== null && this.GrappedElement) {
        var Table = document.getElementById('CatalogItemsContainer')
        var TableTop = Table?.getBoundingClientRect().top
        var TableHeigth = Table?.getBoundingClientRect().height
        var RowsSize = 0
        if (TableHeigth) RowsSize = TableHeigth / this.CookiesData?.Items?.length
        var MouseTop = event.clientY
        var Top = MouseTop
        if (TableTop && RowsSize) {
          Top = Math.max(0, MouseTop - TableTop)
        }
        // console.log('scsc', Top, TableHeigth)
        if (RowsSize && Math.floor(Top / RowsSize) !== this.NewIndex) {
          this.NewIndex = Math.floor(Top / RowsSize)
          // ///////////////////////////////
          var OldArray = this.MEMRead('D_Catalog_Items')
          var NewArray = OldArray.slice(0, this.GrappedIndex).concat(OldArray.slice(this.GrappedIndex + 1))
          NewArray.splice(this.NewIndex, 0, this.GrappedItem)
          this.GrappedIndex = this.NewIndex
          this.MEMWrite('D_Catalog_Items', NewArray)
        }
      }
    },
    IsTouching () {},
    SaveQRDataURI (dataUrl :string, id :any) {
      this.MEMWrite(['D_CatalogEdit_ItemsQRData', `Item_${id}`], dataUrl)
    },
    DownloadAllQRs () {
      var Images = this.MEMRead('D_Catalog_Items').map((item :any) => {
        return {
          Name: item.Code + ' - ' + item.Name,
          UrlOrURI: this.MEMRead(['D_CatalogEdit_ItemsQRData', `Item_${item.ID}`])
        }
      })
      this.ImagesToZip(Images, 'QRCodes_' + this.CookiesData.Name)
    },
    DownloadAllImages () {
      var Images = this.MEMRead('D_Catalog_Items').map((item :any) => {
        return {
          Name: item.Code + ' - ' + item.Name,
          UrlOrURI: item.ImgURL
        }
      })
      this.ImagesToZip(Images, 'Images_' + this.CookiesData.Name)
    },
    PostMedia (Item :any, Res:string, Media?:any) {
      this.PostImage(this.MEMRead(Res)).then((value :any) => {
        this.Post(Media?.ID ? 'PATCH' : 'POST', 'Catalogs', { URL: value }, `/${this.CookiesData.ID}/${Item.ID}/Media` + (Media?.ID ? `/${Media.ID}` : '')).then(() => {
          this.ReadDialogData()
          this.GlobalSaveDone()
          if (!Media) this.MEMWrite(Res, '')
        }, error => {
          this.ReadDialogData()
          this.GlobalSaveDone(false)
          if (!Media) this.MEMWrite(Res, '')
          error.CookiesError = 'Error in Editing'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      })
    },
    RemoveMedia (Item :any, Media:any) {
      this.Saving = true
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Catalogs', AddQuery: `/${this.CookiesData.ID}/${Item.ID}/Media/${Media.ID}`, Text: 'Are you sure you want to delete <strong>the image</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.AfterRemove, AfterDialogFunctionValue: [Media.URL] } as GeneralAcceptDialogData, {})
    },
    MakeDefaultMedia (item :any, media :any) {
      var EditedMedia = item.Media.map((value :any) => {
        if (value.ID === media.ID) {
          value.IsDefault = true
        } else {
          value.IsDefault = false
        }
        return value
      })
      this.EditItem({ target: { innerText: EditedMedia } }, item, 'Media')
    },
    ChangeHiddenState (item :any) {
      this.GlobalSave()
      var NewTags = {
        ...item?.Tags
      }
      console.log(NewTags)
      console.log(item?.Tags?.IsHidden)
      console.log(item?.Tags?.IsHidden ? undefined : true)
      NewTags.IsHidden = item?.Tags?.IsHidden ? undefined : true
      this.Post('PATCH', 'Catalogs', {
        Tags: { ...NewTags }
      }, `/${this.CookiesData.ID}/Items/${item.ID}`).then(() => {
        this.ReadDialogData()
        this.GlobalSaveDone()
        // this.Saving = false
      }, error => {
        this.GlobalSaveDone(false)
        // this.Saving = false
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  created () {
    this.MEMWrite('D_Catalog_Items', [])
    const _this = this
    window.addEventListener('mouseup', function () {
      _this.StopGrapping()
    })
    window.addEventListener('mouseleave', function () {
      _this.StopGrapping()
    })
    window.addEventListener('touchend', function () {
      _this.StopGrapping()
    })
    // if (this.DialogData.Data) {
    //   this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data)
    // }
    // this.MEMWrite('D_Catalog_AddedItems', [])
  },
  mounted () {
    if (this.DialogData.Data) {
      // this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data)
    }
    if (this.DialogData.Editing) {
      this.ReadDialogData(false)
    }
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_Catalog')
    const _this = this
    window.removeEventListener('mouseleave', function () {
      _this.StopGrapping()
    })
    window.removeEventListener('mouseup', function () {
      _this.StopGrapping()
    })
    window.removeEventListener('touchend', function () {
      _this.StopGrapping()
    })
  },
  computed: {
    CashedTrans () {
      return {
        Delete: this.Translate('Delete'),
        Sort: this.Translate('Sort'),
        NewImage: this.Translate('New Image'),
        Show: this.Translate('Show'),
        Default: this.Translate('Make Default'),
        Hidden: this.Translate('Hidden'),
        NotHidden: this.Translate('Not Hidden')
      }
    }
  }
  // watch: {
  //   CurrentDialogs: function (newVal, oldVal) { // watch it
  //     if (newVal < oldVal) {
  //       this.ReadDialogData()
  //     }
  //   }
  // }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2d03a297": (_ctx.Theme.Shade1),
  "b6472ac4": (_ctx.Theme.BG6)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__